.main-header {
  background: map-get($colors, header) !important;
  padding: 0.35rem 0rem !important;
  height: 3rem ;
  width: 100%;
}

.wrap-whole {
  display: flex;
  flex-flow: row nowrap;
  height: 100vh;
}