.ag-theme-alpine {
  --ag-font-family: silkaregular !important;
  --ag-header-background-color: #FFF !important;
  --ag-row-hover-color: #FAFAFC !important;
  --ag-background-color: #FFF !important;
  --ag-odd-row-background-color: #FFF !important;
  --ag-selected-row-background-color: #FFF !important;
  --ag-header-foreground-color: #70777F !important;
  --ag-data-color: #0D0D0D !important;
  --ag-checkbox-indeterminate-color: #165CF3 !important;
  --ag-checkbox-checked-color: #165CF3 !important;
  --ag-checkbox-unchecked-color: #98989A !important;
  --ag-input-focus-box-shadow: none !important;
  --ag-borders-critical: none !important;
  --ag-row-border-color: #EBECF1 !important
}

.ag-theme-alpine .ag-root-wrapper {
  border-radius: 1rem !important;
  border: none !important;
}

.ag-theme-alpine .ag-header-container {
  background: #FFF !important;
  box-shadow: inset 0 -1rem 1rem -1rem map-get($colors, display-background) !important;
  // box-shadow: inset 0 -1rem 1rem -1rem #d3d1d1 !important;
}

.ag-theme-alpine .ag-paging-panel {
  border: none !important;
  background: #FFF !important;
  box-shadow: inset 0 1rem 1rem -1rem map-get($colors, display-background) !important;
  font: map-get($fonts, h5-regular);
}


.ag-theme-alpine .ag-header-cell-label {
  font: map-get($fonts, h4-regular)
}


.ag-theme-alpine .ag-cell-value {
  font: map-get($fonts, h5-regular);
}

.ag-cell-actions-style {
  box-shadow: 0 1rem 2.5rem map-get($colors, display-background) !important;
  background: #fff !important;
  height: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.ag-theme-alpine .ag-cell {
  border: none !important;
}

// Status renderer

@each $name,
$bgcolor in $grid-colors {
  .status-variant-#{$name} {
    display: inline-flex;
    border-radius: 0.25rem;
    border:0.125rem solid map-get($grid-colors, #{$name}-br);
    background: $bgcolor 0% 0% no-repeat padding-box;
    padding: 0.025rem 0.5rem !important;
    color: map-get($grid-colors, #{$name}-font);
  }
}

.ag-theme-alpine .empty-label {
  font: map-get($fonts, h5-regular)
}




@media(max-width: 835px) {

  .ag-theme-alpine .ag-header-container {
    box-shadow: inset 0 -1rem 1rem -1rem map-get($colors, display-background),
      inset 0 1rem 1rem -1rem map-get($colors, display-background) !important;
  }

  .ag-theme-alpine .ag-root-wrapper {
    border-radius: 0rem !important;
  }

  .ag-theme-alpine .ag-header-cell-label {
    font: map-get($fonts-mobile, h4)
  }

  .ag-theme-alpine .ag-cell-value {
    font: map-get($fonts-mobile, h5);
  }

  .ag-theme-alpine .empty-label {
    font: map-get($fonts-mobile, h5)
  }

}


//cell animate

@keyframes moving-gradient {
  0% {
    background-position: 0 -250px;
  }

  100% {
    background-position: 0 250px;
  }
}

.ag-cell-data-changed,
.ag-cell-data-changed-animation {
  background: linear-gradient(to top, #F4F4FB 20%, #FAFAFC 50%, #F4F4FB 80%);
  animation-name: moving-gradient;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  opacity: 0.2;
}

.ag-root {
  -webkit-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important; 
  user-select: text !important;
}

.ag-header-viewport{
  z-index: 1;
}