  .report-viewpage-border {
      border: 1px solid map-get($colors, report-page-border) !important;  
    }
  .report-media-box{
      box-shadow: 0px 2px 4px map-get($colors, report-media-box-shadow ) ;
      background-color: map-get($colors, report-media-box-bg) ;
      border: 1px solid map-get($colors, report-page-border) ;
  }

  .report-media-box:hover{
    border: 1px solid map-get($colors, report-media-box-border-hover ) !important;
    background: map-get($colors, report-media-box-bg-hover ) !important;
  }

 
  .reports-view .grid-header .grid-header-cell span{
    font:map-get($fonts, h5-regular) !important;
  }

  .reports-view .grid-row .grid-row-cell div{
    font:map-get($fonts, conversation-history-user-label) !important;
  }

  .report-date-selector-border {
    padding-top: 6px;
    padding-bottom: 6px;
    padding-inline: 10px;
    border: 1px;
    border-color: #DCE1E9;
    border-radius: 6px;
    border-style: solid;
  }

  .report-date-selector-border:hover {
    padding-top: 6px;
    padding-bottom: 6px;
    padding-inline: 10px;
    border: 1px;
    border-color: #1450FF;
    border-radius: 6px;
    border-style: solid;
  }
  
 