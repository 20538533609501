.plan-validity {
  width: calc(100% - 1rem) !important;
  border-radius: 0.5rem 0.5rem 0 0;
}


.plans-paper {
  border-radius: 9px !important;
  box-shadow: none !important;

  @media (max-width: 1200px) and (min-width: 600px) {
    width: 27rem !important;
  }
}

.plan-card-inactive {
  background-color: map-get($colors, plans-inactive);
  border-radius: 0.5rem;
  padding: 2rem;
  max-width: fit-content;
  min-width: 35vh;
  min-height: 30rem;
  margin: auto;

  &>.plan-features {
    background: map-get($colors, primary-background-pink) !important;
  }
}

.plan-card {
  background-color: map-get($colors, secondary);
  border-radius: 0.5rem;
  padding: 2rem;
  max-width: fit-content;
  min-width: 35vh;
  min-height: 30rem;
  margin: auto;
}

.plan-price {
  text-decoration: line-through;
  padding-right: 0.25rem;
  text-decoration-color: map-get($colors, primary-danger);
}

.foot-note {
  width: calc(100% - 4rem);
  bottom: 2rem;
}

.add-on-main-card {
  background-color: map-get($colors, secondary);
  border-radius: 0.5rem;
  padding: 1.5rem;
  margin-top: 3rem;
  margin-left: 1rem;
}


.add-on-main-card-inactive {
  background-color: map-get($colors, add-on-inactive);
  border-radius: 0.5rem;
  padding: 1.5rem;
  margin-top: 3rem;
  margin-left: 1rem;

  &>.add-on-content {
    background: map-get($colors, primary-background-pink)
  }

  &>.d-flex>.button-add-on {
    background: map-get($colors, primary-background-pink)
  }
}

.addons-list {
  width: 0.35rem !important;
  height: 0.45rem !important;
}


.plan-features {
  margin-bottom: 1rem;
  width: 86%;
}

.plans-button {
  background: white !important;
  color: #396AFF !important;
}

.plans-button-active {
  background: #396AFF !important;
  color: white !important;
  font: map-get($fonts, h6) !important;

  @media (max-width: 835px) {
    font: map-get($fonts, h5-regular) !important;
  }
}

.add-on-drop-card {
  border-radius: 0 0 1.5rem 1.5rem;
}

.add-on-text-rotation {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.butons-transform {
  text-transform: capitalize !important;
}

.add-phone-number-paper-border {
  border: 0.125rem solid map-get($colors, plans-border-box) !important;
}

.payment-status-word {
  word-break: break-all !important;
}

.btn-bottom-display {
  margin-top: 50% !important;
}

.add-on-secondary-btn-hover {
  background-color: map-get($colors, secondary) !important;
  color: map-get($colors, primary-light) !important;

  &:hover {
    background: map-get($colors, secondary) !important;
    color: map-get($colors, primary-light) !important;
  }
}

.add-on-primary-btn-hover {
  background-color: map-get($colors, primary-light);
  color: map-get($colors, secondary);

  &:hover {
    background: map-get($colors, primary-btn-focus) !important;
  }
}

.product-border {
  border: 2px solid map-get($colors, product-border-view) !important;
}