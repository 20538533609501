.page-container {
  box-shadow: none !important;
  height: 80vh !important;
  margin-top: 3rem !important;
  background: map-get($colors, secondary) !important;
  border-radius: 0.5rem !important;
  padding: 1.5rem !important;

  @media (max-width: 835px) {
    height: 95vh !important;
    margin: 0 !important;
    border-radius: 0 !important;
    padding: 0.5rem !important;
  }

  @media (min-width: 769px) and (max-width: 883px) {
    width: 80% !important;
  }

  @media (min-width: 884px) and (max-width: 1024px) {
    width: 50% !important;
  }
}

.profile-menu-position {
  left: 60% !important;

  @media (max-width: 835px) {
    left: 31% !important;
    top: 35.5% !important;
  }
}

