@media (max-width: 835px) {
  .grid-title-search-icon {
    width: 44%;
    top: 1.3rem;
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .grid-title-search {
    background: map-get($colors, grid-search) 0% 0% no-repeat padding-box !important;
    border-radius: 6px !important;
    opacity: 1;
    width: 25%;
    padding: 0.3rem;
    color: map-get($colors, grid-search-font-color);
  }

  .grid-title-input-input {
    width: 6.4rem !important;
  }

  .icon {
    width: 1rem;
  }
}

.table-sort {
  &-header {
    font: map-get($fonts, h4);

    &.last-child th,
    &.last-child td {
      border: none !important;
    }

    &-cell:first-child {
      border-radius: 1rem 0rem 0rem 0rem;
    }

    &-cell:last-child {
      border-radius: 0rem 1rem 0rem 0rem;
    }

    &-cell {
      color: map-get($colors, grid-header-font-color) !important;
      background-color: map-get($colors, grid-header) !important;
      width: -webkit-fill-available;
      padding: 1rem;

      &:hover {
        color: map-get($colors, grid-header-font-color) !important;
      }
    }
  }
}

.grid-title {
  text-align: left;
  font: map-get($fonts, h1) !important;
  color: map-get($colors, grid-title);
  opacity: 1;
  flex-grow: 1;
  display: "none";
}

.grid-title-search {
  background: map-get($colors, grid-search) 0% 0% no-repeat padding-box !important;
  border-radius: 0.5rem !important;
  opacity: 1;
  width: 20%;
  padding: 0.5rem 0rem;
  color: map-get($colors, grid-search-font-color);
}

.grid-title-search-icon {
  width: 35%;
  top: 1.5rem;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.grid-sort-header-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1;
  margin: -1;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 20;
  width: 1;
}

.grid {
  &-header {
    font: map-get($fonts, h6);

    &.fixed {
      position: fixed;
      z-index: 1;
    }

    &>&-cell:first-child {
      border-radius: 1rem 0rem 0rem 0rem;
    }

    &>&-cell:last-child {
      border-radius: 0rem 1rem 0rem 0rem;
    }

    &-cell {
      color: map-get($colors, grid-header-font-color) !important;
      background-color: map-get($colors, grid-header) !important;
      width: -webkit-fill-available;
      padding: 1rem;

      &:hover {
        font-weight: bold !important;
        color: map-get($colors, grid-header-font-color) !important;
      }

      &>span>svg {
        height: 1.125rem;
        color: map-get($colors, grid-sorticon);
      }
    }
  }

  &-row {
    margin: 0.125rem 0;
    background-color: map-get($colors, grid-data);
    color: map-get($colors, grid-data-font-color);

    font: map-get($fonts, h6);

    &-cell {
      width: -webkit-fill-available;
      word-break: break-word;
    }

    &:hover {
      background: map-get($colors, grid-highlight-hover) 0% 0% no-repeat padding-box !important;
    }

    &.active:hover {
      background: linear-gradient(to right,
          map-get($colors, primary-success) 0.25rem,
          map-get($colors, grid-highlight-hover) 0%) 0px 4px no-repeat padding-box !important;
    }

    &.blocked:hover {
      background: linear-gradient(to right,
          map-get($colors, primary-danger) 0.25rem,
          map-get($colors, grid-highlight-hover) 0%) 0px 4px no-repeat padding-box !important;
    }

    &.unsubscribed:hover {
      background: linear-gradient(to right,
          map-get($colors, primary-warning) 0.25rem,
          map-get($colors, grid-highlight-hover) 0%) 0px 4px no-repeat padding-box !important;
    }

    &.inactive:hover {
      background: linear-gradient(to right,
          map-get($colors, primary-inactive) 0.25rem,
          map-get($colors, grid-highlight-hover) 0%) 0px 4px no-repeat padding-box !important;
    }

    &.disable-hover:hover {
      background-color: map-get($colors, secondary) !important;
    }
  }
}

.repeat-y {
  background-repeat: repeat-y;
}

.w-p-delete {
  width: 91.5%;
}

.checkbox input[type="checkbox"] {
  background-color: #fff;
  border-radius: 2px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 17px;
  height: 17px;
  cursor: pointer;
  position: relative;
  top: 5px;
  border: 2px solid #fff;
  outline: none;
}

.checkbox input[type="checkbox"]:checked {
  background-color: #000;
  background: #fff url("https://e7.pngegg.com/pngimages/884/38/png-clipart-computer-icons-check-mark-black-tick-angle-rectangle.png") 3px 3px no-repeat;
  background-position: center;
  background-size: cover;
}

.select-border {
  border: 1px solid map-get($colors, select-border);
}

.avatar {
  color: #FFFFFF;
  display: flex;
  border-radius: 50%;
  font-family: silkaregular;
  font-weight: 500;
}


.inner-circle-top {
  position: absolute;
  background: #a9aaab;
  border-radius: 50%;
  height: 27px;
  width: 27px;
}

.inner-circle-bottom {
  position: absolute;
  background: #a9aaab;
  border-radius: 50%;
  height: 27px;
  width: 27px;
  top: 23px;
  left: 23px
}

.inner-circle-top-4 {
  position: absolute;
  background: #a9aaab;
  border-radius: 50%;
  height: 23px;
  width: 23px;
}

.inner-circle-bottom-4 {
  position: absolute;
  background: #a9aaab;
  border-radius: 50%;
  height: 23px;
  width: 23px;
  top: 17px;
  left: 17px
}

.user-alert-link {
  width: calc(100% - 2.5rem);
}

@-webkit-keyframes grid-priority-animation {
  from {
    background-color: red;
  }

  to {
    background-color: inherit;
  }
}

@-moz-keyframes grid-priority-animation {
  from {
    background-color: red;
  }

  to {
    background-color: inherit;
  }
}

@keyframes grid-priority-animation {
  from {
    background-color: red;
  }

  to {
    background-color: inherit;
  }
}


.grid-priority-animation {
  -webkit-animation: grid-priority-animation 1s infinite;
  -moz-animation: grid-priority-animation 1s infinite;
}

.border-badge-radius {
  border-radius: 50% !important;
  vertical-align: middle;
  display: inline-table;
  width: 1.625rem !important;
}

.badge-count-display {
  display: table-cell;
  vertical-align: middle;
}