.bottom-drawer {
    background: map-get($colors, secondary);
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    z-index: 200;
    border-radius: 0.25rem 0.25rem 0 0;
    box-shadow: 0.25rem 0rem 0.75rem map-get($colors, menu-box-shadow);
    transform: translateY(100%);
    transition: transform 0.5s ease-out;
}

.bottom-drawer.open {
    transform: translateY(0);
}

.blurbackground {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: map-get($colors, menu-box-shadow);
    z-index: 100;
    top: 0;
    right: 0;
    left: 0;
}