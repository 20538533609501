@each $key,
$val in $toaster-colors {
    .bg-toaster-#{$key} {
        background: linear-gradient(to right,
                $val 0.25rem,
                map-get($colors, secondary) 0%) !important;
        box-shadow: 0px 12px 20px map-get($colors, toaster-shadow);
        border-radius: 2px;
    }
}

.toaster {
    width: 24rem;
    right: -28rem;
    position: absolute;
    word-break: break-word;
    @media (min-width: 310px) and (max-width: 700px) {
        width: 21rem;
    }

    &.open {
        right: 0rem;
        -webkit-transition: all 0.4s linear;
        transition: all 0.4s linear;
    }

    &.close {
        right: -28rem;
        -webkit-transition: all 0.5s linear;
        transition: all 0.5s linear;
    }

    &:hover {
        .d-hover {
            visibility: visible !important;
        }
    }
}

.toaster-container {
    top: 10rem;
    @media (min-width: 310px) and (max-width: 700px) {
        top:77%;
    }
    
    &.open {
        right: 1rem;
        -webkit-transition: all 0.4s linear;
        transition: all 0.4s linear;
    }

    &.close {
        right: -28rem;
        -webkit-transition: all 0.5s linear;
        transition: all 0.5s linear;
    }
}

@each $key,
$val in $toaster-colors {
    .bg-alert-#{$key} {
        background: $val !important;
    }
}

.alert-message {
    height: 0rem;
    z-index: 1;

    &.open {
        height: 3.5rem;
        -webkit-transition: all 0.5s linear;
        transition: all 0.5s linear;
    }

    &.close {
        height: 0rem;
        -webkit-transition: all 0.5s linear;
        transition: all 0.5s linear;
    }
}