.stepper-horizontal {
    display: flex;
    width: 100%;
    margin: 0 auto;
    .stepper {
      position: relative;
      padding: 0.2rem;
      z-index: 2;
      &:last-child .stepper-bar-left,
      &:last-child .stepper-bar-right {
        display: none;
      }
      .stepper-label-circle {
        margin: auto;
        z-index: 2;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background: map-get($colors, secondary) 0% 0% no-repeat padding-box;
        box-shadow: 0px 4px 5px #0C0C0C33;
        border: 4px solid #DCDEF6;
        transform: translate(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
      }
  
      &.done {
        .stepper-label-circle {
          background-color: #306AF5;
          color: map-get($colors, secondary);
          border: 4px solid #306AF5; 
          &:before {
              text-align: center;
              font: normal normal bold 9px/11px Helvetica;
              margin: auto;
              content: url(../../icons/stepper-tickmark-icon.svg);
          }
          * {
            display: none;
          }
        }
        .stepper-label-title {
          color: #306AF5;
        }
      }
  
      &.editing {
        .stepper-label-circle {
          background: map-get($colors, secondary);
          color: #306AF5;
          border: 4px solid map-get($colors, secondary);
          &:before {
            text-align: center;
            font-size: large;
            margin: auto;
            content: url(./../../icons/stepper-current-step-icon.svg);
            color: #306af5;
          }
          * {
            display: none;
          }
        }
        .stepper-label-title {
          color: #306AF5;
        }
      }
  
      .stepper-label-title {
        margin-top: 0.5rem;
        margin-right: 1.2rem;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font: normal normal bold 9px/11px Helvetica;
        color: #AFAEAF;
      }
      .stepper-bar-left,
      .stepper-bar-right {
        position: absolute;
        top: 16px;
        background-color: #DCDEF6;
        border: solid #DCDEF6;
        border-width: 1px 0;
      }
      .stepper-bar-left {
        width: calc(100% - 18px);
        left: 50%;
        z-index: -1;
      }
      .stepper-bar-right {
        width: 0;
        left: 50%;
        z-index: -1;
        transition: width 500ms ease-in-out;
      }
  
      &.done .stepper-bar-right {
        background-color: map-get($colors, primary-link);
        border-color: map-get($colors, primary-link);
        z-index: 3;
        width: calc(100% - 18px);
      }
    }
  }
  