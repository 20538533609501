.tooltip-container {
    display: inline-block;
    position: relative;

    .tooltip {
        visibility: hidden;
        width: auto;
        white-space: nowrap;
        background-color: rgba(97, 97, 97, 0.92);
        color: white;
        text-align: center;
        border-radius: 4px;
        padding: 4px 6px;
        transition: opacity 0.3s;
        opacity: 0; 
        pointer-events: none; 
        position: absolute; 
        top: calc(100% + -5px); 
        left: 50%;
        transform: translateX(-50%); 

        .tooltip-text {
            font-size: 0.6875rem;
        }
    }

    &:hover .tooltip {
        visibility: visible;
        opacity: 1;
        pointer-events: auto; 
    }
}
