.stepper-icon-root {
    border: 4px solid #DCDEF6
}

.stepper-icon-active {
    box-shadow: 0px 4px 5px #0C0C0C33;
}

.stepper-line-color {
    border-color: #DCDEF6 !important
}

.stepper-icon-root-none {
    border: none !important
}

.stepper-line-height {
    height: 0.3rem;
    border: 0px;
    background-color: #BFBFC1;
    border-radius: 1px;
    margin: 0.5rem 1rem 0 1rem;
}

.index-icon {
    z-index: 1;
}

.signup-mobile-container {
    border-bottom-left-radius: 125% 15rem;
    border-bottom-right-radius: 125% 15rem;
}

.sign-up-divider {
    border-bottom: 0.125rem solid map-get($colors, secondary) !important;
}

.autocomplete-popper{
    z-index: 9999 !important;
}

.sign-up-mobile-height{
    height: 76.5vh !important;
}