@import "../variables.scss";

@each $key,
$val in $dashboard-colors {
  .bg-dashboard-#{$key} {
    background: $val !important;
  }
}

.dashboard-line {
  border-bottom: 0.15rem solid map-get($colors, secondary) !important;
}

.display-title {
  font-family: map-get($fonts, h6) !important;
  letter-spacing: 0.58px !important;
  color: #727272 !important;
  opacity: 1;

  @media only screen and (max-width: 835px) {
    font-size: 0.89rem !important;
    font-weight: 500;
  }
}

.display-title-dashboard-font {
  font-size: 0.75rem !important;
}

.card-display {
  box-shadow: 0px 1px 2px #B5B5B5 !important;
  border: 1px solid #B5B5B5 !important;
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
}


.tool-icon {
  color: map-get($colors, secondary-main) !important;

  @media (max-width: 320px) {
  }
}

.progress-shadow {
  box-shadow: 0px 8px 24px map-get($colors, menu-box-shadow) !important;
}

.statistics-box {
  display: inline-block;
  width: 20rem;
  height: 8vh;
  background: #E7EDFF !important;
  border-radius: 0.6rem !important;

  @media (max-width: 835px) {
    width: 15.4rem !important;
    height: 6.7vh !important;
  }

  @media screen and (max-width:650px) {
    width: 14.5rem !important;
    height: 6vh !important;
  }

  @media screen and (max-width:415px) {
    width: 14rem !important;
    height: 5vh !important;
  }

  @media screen and (max-width:375px) {
    width: 14rem !important;
    height: 5.5vh !important;
  }
}

.stat-button {
  border-radius: 0.6rem !important;
  background: white !important;
  color: #396AFF !important;
  box-shadow: none !important;
  margin-top: 0.6rem !important;
  margin-left: 0.8rem !important;
  font: map-get($fonts, h6) !important;

  @media (max-width: 835px) {
    font: map-get($fonts, h5-regular) !important;
  }
}

.stat-button-mobile-view {
  text-decoration: none !important;
  border-radius: 0.5rem;
  background: white;
  color: #396AFF;
  padding: 0.5rem 0.7rem !important;
  box-shadow: none !important;
  font: map-get($fonts, h5-regular) !important;
  margin: 0 0.25rem;
}

.stat-button-active {
  background: #396AFF !important;
  color: white !important;
  font: map-get($fonts, h6) !important;

  @media (max-width: 835px) {
    font: map-get($fonts, h5-regular) !important;
  }
}

.stat-title {
  font-family: map-get($fonts, h6) !important;
  letter-spacing: 0.58px !important;
  color: #727272 !important;
  font-size: 1.7rem !important;

  @media (max-width: 835px) {
    font-size: 1.5rem !important;
  }
}

.stat-title-mobile-view {
  font: map-get($fonts, h6) !important;
  color: #727272 !important;
}


.avatar-mobile-view {
  background-color: transparent !important;
}

.tool-icon-mobile {
  width: 1.2rem !important;
  height: 1.2rem !important;
}


.contact-card-mobile-view {
  border-radius: 8% !important;
}

.stat-overflow {
  overflow: auto;
  overflow-x: hidden !important;

  @media (min-width: 414px) {
    overflow-x: hidden !important;
  }

  @media (min-width: 375px) {
    overflow-x: hidden !important;
  }

  @media (max-width: 320px) {
    overflow: auto !important;
  }
}

.mobile-view-stat {
  padding-bottom: 2rem;

  @media (min-width: 414px) {
    padding-bottom: 5rem !important;
  }
}

.stat-mobile-view-box-fix {
  margin-left: 0.56rem !important;
  margin-top: 0.45rem !important;

  @media (max-width:415px) {
    margin-left: 0.45rem !important;
    margin-top: 0.35rem !important;
  }

  @media (max-width: 375px) {
    margin-left: 0.45rem !important;
    margin-top: 0.2rem !important;
  }
}


progress {
  height: 2.7vh;
  border-radius: 20px;
  background: none;
  border: 0;
  border-radius: 20px;
}

progress::-webkit-progress-bar {
  height: 2vh;
  border-radius: 20px;
  border-radius: 20px;
}

progress::-webkit-progress-value {
  height: 2vh;
  padding-left: 1rem;
  border-radius: 20px;
}

progress::-moz-progress-bar {
  height: 2vh;
  border-radius: 20px;
}


.mobile-view-h {
  height: 100vh !important;

  @media (max-width: 380px) {
    height: 88vh !important;
  }
}

.tooltip-recieved {
  margin-right: 1rem !important;

  @media screen and (max-width: 380px) {
    margin-right: 0 !important;
  }
}

.dashboard-credit-font {
  top: -1rem !important;
  font-size: x-small !important;

  @media (min-width: 380px) and (max-width: 410px) {
    top: -2rem !important;
    font: normal normal bold 8px/10px Helvetica !important;
  }

  @media (min-width: 310px) and (max-width: 379px) {
    top: -2rem !important;
    font: normal normal bold 7px/10px Helvetica !important;
  }

}

.font-charges-apply {
  font-size: x-small !important;

  @media (min-width: 380px) and (max-width: 410px) {
    font: normal normal bold 8px/10px Helvetica !important;
  }

  @media (min-width: 310px) and (max-width: 379px) {
    margin-top: -0.5rem;
    font: normal normal bold 7px/10px Helvetica !important;
  }
}

.dashboard-used-limit {
  top: -1rem !important;
  font-size: x-small !important;

  @media (min-width: 380px) and (max-width: 410px) {
    top: -2rem !important;
    font: normal normal normal 8px/10px Helvetica !important;
  }

  @media (min-width: 310px) and (max-width: 379px) {
    top: -2rem !important;
    font: normal normal normal 7.2px/10px Helvetica !important;
  }
}

.dashboard-group-font {
  @media (min-width: 371px) and (max-width: 410px) {
    top: -1rem !important;
  }

  @media (min-width: 310px) and (max-width: 370px) {
    top: -1.5rem !important;
  }
}

.dashboard-progress-bar {
  top: -1rem !important;

  @media (min-width: 380px) and (max-width: 410px) {
    top: -2rem !important;
  }

  @media (min-width: 310px) and (max-width: 379px) {
    top: -2rem !important;
  }
}

.dashboard-progress-bar-line {
  margin-top: -1.5rem !important;

  @media (min-width: 380px) and (max-width: 410px) {
    margin-top: -2.5rem !important;
  }

  @media (min-width: 310px) and (max-width: 379px) {
    margin-top: -2.5rem !important;
  }
}

.dashboard-progress-bar-line-50 {
  margin-top: -1rem !important;

  @media (min-width: 380px) and (max-width: 410px) {
    margin-top: -2rem !important;
  }

  @media (min-width: 310px) and (max-width: 379px) {
    margin-top: -2rem !important;
  }
}

.dashboard-number-font {
  font-size: x-small !important;

  @media (min-width: 380px) and (max-width: 410px) {
    font: normal normal normal 9px/9px Helvetica !important;
  }

  @media (min-width: 310px) and (max-width: 379px) {
    font: normal normal normal 9px/9px Helvetica !important;
  }
}

.charges-apply {
  color: black;
  border-bottom: 1px solid blue;
}

.dashboard-statistic-border {
  border: 0.0625rem solid map-get($colors, dashboard-stat-border) !important;
}

.dashboard-mobile-stat-border {
  border: 0.03125rem solid map-get($colors, dashboard-mobile-stat-border);
}