.hand {
  cursor: pointer;
  padding: 2px;
}

.disabled-time {
  padding: 2px;
}

.hours,
.minutes {
  height: 8rem;
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  font: map-get($fonts, caption);
}

.hours::-webkit-scrollbar,
.minutes::-webkit-scrollbar {
  height: 0.2rem;
  width: 0.2rem;

  @media (max-width: 639px) {
    width: 0.1rem;
  }

  /* Track */
  &-track {
    background: inherit;
    border-radius: 0.25rem;

    &:hover {
      background: map-get($colors, secondary-main);
    }
  }

  /* Handle */
  &-thumb {
    height: 2rem;
    width: 2rem;
    border-radius: 0.25rem;
    background: map-get($colors, secondary-light);

    /* Handle on hover */
    &:hover {
      background: map-get($colors, scroll-thumb-hover);
    }
  }
}

.hand:hover {
  background-color: map-get($colors, secondary-main);
  border-radius: 15px;
}

.selected {
  background-color: map-get($colors, primary-link);
  color: map-get($colors, secondary);
  border-radius: 15px;
}

.actions {
  display: flex !important;
  background: map-get($colors, secondary) !important;
}

.selected:hover {
  background-color: map-get($colors, primary-link);
  color: map-get($colors, secondary);
  border-radius: 15px;
}

.datetime-divider {
  border-right: 1px solid map-get($colors, primary-divider);
}

/*Calendar CSs*/

abbr[title] {
  text-decoration: none !important;
}

.react-calendar {
  width: 270px;
  max-width: 100%;
  background: map-get($colors, secondary) !important;
  font: map-get($fonts, caption);
  line-height: 1.125em;
  
  
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer>* {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 34px;
  background: none;
}

.react-calendar__navigation button[disabled] {
  color: map-get($colors, secondary-main);
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  color: map-get($colors, secondary-accent);
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.2em;
}

.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.5em / 0.5) calc(0.5em / 0.5);
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: map-get($colors, secondary-accent);
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 1em 0.5em;
  margin-bottom:0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.5em 0.5em;
  background: none;
}

.react-calendar__tile:disabled {
  color: map-get($colors, secondary-main);
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: map-get($colors, secondary-main);
  border-radius: 15px;
}

.react-calendar__tile--now {
  background: map-get($colors, secondary-main);
  border-radius: 15px;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background-color: map-get($colors, secondary-main);
  border-radius: 15px;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background-color: map-get($colors, secondary-main);
  border-radius: 15px;
}

.react-calendar__tile--active {
  background-color: map-get($colors, primary-link);
  color: map-get($colors, secondary);
  border-radius: 15px;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background-color: map-get($colors, primary-link);
  border-radius: 15px;
}

.react-calendar__navigation__label__labelText,
.react-calendar__navigation__label__labelText--from,
.time_header {
  font: map-get($fonts, caption);
  font-weight: bold;
  background: map-get($colors, primary-text-field);
  color: #000000DE;
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
  text-align: center;
  
}


.react-calendar__navigation__label__labelText:hover,
.react-calendar__navigation__label__labelText--from:hover,
.time_header:hover {
  background-color: map-get($colors, secondary-main);

}

.time-meridiem-border {
  border: 1px solid map-get($colors, outline-display-time-hour-period)
}

.time-disable-meridiem-border {
  border: 1px solid map-get($colors, outline-disabled-time-hour-period)
}