.groupchat-info-border {
    border: 1px solid map-get($colors, groupchat-info-border) !important;
}

.groupchat-info-background {
    background-color: map-get($colors, groupchat-info-background);
}

.leave-this-group-color {
    color: map-get($colors, groupchat-leave)
}

.more-button:active {
    border: 1px solid #1450FF;
}

.more-button:hover {
    box-shadow: 0px 2px 4px #AFBED1
}

.groupchat-edit-button:hover {
    background-color: 0px 2px 4px #AFBED1;
}

.groupchat-edit-button:active {
    background-color: 0px 2px 4px #1450FF;
}