.settings-mobile-paper-border {
  border-bottom: 1px solid map-get($colors, settings-border-box) !important;
  border-left: 1px solid map-get($colors, settings-border-box) !important;
  border-right: 1px solid map-get($colors, settings-border-box) !important;
}

.settings-paper-border {
  border: 1px solid map-get($colors, settings-border-box) !important;
}

.checked-color {
  color: #22313f !important;
}

.back-icon-height {
  height: 1.3rem !important;
}

.counter {
  border: 0.05rem solid map-get($colors, secondary-btn-highlight-hover);
  width: fit-content;

  &-inverted {
    border: 0.05rem solid map-get($colors, secondary);
    width: fit-content;
    color: map-get($colors, secondary);
  }
}

.change-password-vertical-line {
  border-right: 2px solid map-get($colors, settings-border-box) !important;
}

.settings-text-field-border {
  border: 1px solid map-get($colors, text-field-focused) !important;
}

.change-password-horizontal-line {
  border-bottom: 2px solid map-get($colors, settings-border-box) !important;
}
