$font-path: '../fonts/Silka-Roman-Webfont';
$font-path-italic: '../fonts/Silka-Italic-Webfont';

@font-face {
  font-family: "silkablack";
  font-display: swap;
  src: url("#{$font-path}/silka-black-webfont.eot");
  src: url("#{$font-path}/silka-black-webfont.eot?#iefix") format("embedded-opentype"),
    url("#{$font-path}/silka-black-webfont.woff2") format("woff2"),
    url("#{$font-path}/silka-black-webfont.woff") format("woff"),
    url("#{$font-path}/silka-black-webfont.ttf") format("truetype");
}

@font-face {
  font-family: "silkabold";
  font-display: swap;
  src: url("#{$font-path}/silka-bold-webfont.eot");
  src: url("#{$font-path}/silka-bold-webfont.eot?#iefix") format("embedded-opentype"),
    // url("#{$font-path}/silka-bold-webfont.woff2") format("woff2"),
    // url("#{$font-path}/silka-bold-webfont.woff") format("woff"),
    url("#{$font-path}/silka-bold-webfont.ttf") format("truetype");
}

@font-face {
  font-family: "silkaextra_light";
  font-display: swap;
  src: url("#{$font-path}/silka-extralight-webfont.eot");
  src: url("#{$font-path}/silka-extralight-webfont.eot?#iefix") format("embedded-opentype"),
    url("#{$font-path}/silka-extralight-webfont.woff2") format("woff2"),
    url("#{$font-path}/silka-extralight-webfont.woff") format("woff"),
    url("#{$font-path}/silka-extralight-webfont.ttf") format("truetype");
}

@font-face {
  font-family: "silkalight";
  font-display: swap;
  src: url("#{$font-path}/silka-light-webfont.eot");
  src: url("#{$font-path}/silka-light-webfont.eot?#iefix") format("embedded-opentype"),
    url("#{$font-path}/silka-light-webfont.woff2") format("woff2"),
    url("#{$font-path}/silka-light-webfont.woff") format("woff"),
    url("#{$font-path}/silka-light-webfont.ttf") format("truetype");
}

@font-face {
  font-family: "silkamedium";
  font-display: swap;
  src: url("#{$font-path}/silka-medium-webfont.eot");
  src: url("#{$font-path}/silka-medium-webfont.eot?#iefix") format("embedded-opentype"),
    url("#{$font-path}/silka-medium-webfont.woff2") format("woff2"),
    url("#{$font-path}/silka-medium-webfont.woff") format("woff"),
    url("#{$font-path}/silka-medium-webfont.ttf") format("truetype");
}

@font-face {
  font-family: "silkaregular";
  font-display: swap;
  src: url("#{$font-path}/silka-regular-webfont.eot");
  src: url("#{$font-path}/silka-regular-webfont.eot?#iefix") format("embedded-opentype"),
    // url("#{$font-path}/silka-regular-webfont.woff2") format("woff2"),
    // url("#{$font-path}/silka-regular-webfont.woff") format("woff"),
    url("#{$font-path}/silka-regular-webfont.ttf") format("truetype");
}

@font-face {
  font-family: "silkasemibold";
  font-display: swap;
  src: url("#{$font-path}/silka-semibold-webfont.eot");
  src: url("#{$font-path}/silka-semibold-webfont.eot?#iefix") format("embedded-opentype"),
    // url("#{$font-path}/silka-semibold-webfont.woff2") format("woff2"),
    // url("#{$font-path}/silka-semibold-webfont.woff") format("woff"),
    url("#{$font-path}/silka-semibold-webfont.ttf") format("truetype");
}

@font-face {
  font-family: "silkathin";
  font-display: swap;
  src: url("#{$font-path}/silka-thin-webfont.eot");
  src: url("#{$font-path}/silka-thin-webfont.eot?#iefix") format("embedded-opentype"),
    url("#{$font-path}/silka-thin-webfont.woff2") format("woff2"),
    url("#{$font-path}/silka-thin-webfont.woff") format("woff"),
    url("#{$font-path}/silka-thin-webfont.ttf") format("truetype");
}


  //italic


@font-face {
    font-family: 'silkablack_italic';
    font-display: swap;
    src: url('#{$font-path-italic}/silka-blackitalic-webfont.eot');
    src: url('#{$font-path-italic}/silka-blackitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$font-path-italic}/silka-blackitalic-webfont.woff2') format('woff2'),
         url('#{$font-path-italic}/silka-blackitalic-webfont.woff') format('woff'),
         url('#{$font-path-italic}/silka-blackitalic-webfont.ttf') format('truetype');
}




@font-face {
    font-family: 'silkabold_italic';
    font-display: swap;
    src: url('#{$font-path-italic}/silka-bolditalic-webfont.eot');
    src: url('#{$font-path-italic}/silka-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$font-path-italic}/silka-bolditalic-webfont.woff2') format('woff2'),
         url('#{$font-path-italic}/silka-bolditalic-webfont.woff') format('woff'),
         url('#{$font-path-italic}/silka-bolditalic-webfont.ttf') format('truetype');
}




@font-face {
    font-family: 'silkaextra_light_italic';
    font-display: swap;
    src: url('#{$font-path-italic}/silka-extralightitalic-webfont.eot');
    src: url('#{$font-path-italic}/silka-extralightitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$font-path-italic}/silka-extralightitalic-webfont.woff2') format('woff2'),
         url('#{$font-path-italic}/silka-extralightitalic-webfont.woff') format('woff'),
         url('#{$font-path-italic}/silka-extralightitalic-webfont.ttf') format('truetype');
}




@font-face {
    font-family: 'silkalight_italic';
    font-display: swap;
    src: url('#{$font-path-italic}/silka-lightitalic-webfont.eot');
    src: url('#{$font-path-italic}/silka-lightitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$font-path-italic}/silka-lightitalic-webfont.woff2') format('woff2'),
         url('#{$font-path-italic}/silka-lightitalic-webfont.woff') format('woff'),
         url('#{$font-path-italic}/silka-lightitalic-webfont.ttf') format('truetype');
}




@font-face {
    font-family: 'silkamedium_italic';
    font-display: swap;
    src: url('#{$font-path-italic}/silka-mediumitalic-webfont.eot');
    src: url('#{$font-path-italic}/silka-mediumitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$font-path-italic}/silka-mediumitalic-webfont.woff2') format('woff2'),
         url('#{$font-path-italic}/silka-mediumitalic-webfont.woff') format('woff'),
         url('#{$font-path-italic}/silka-mediumitalic-webfont.ttf') format('truetype');
}




@font-face {
    font-family: 'silkaregular_italic';
    font-display: swap;
    src: url('#{$font-path-italic}/silka-regularitalic-webfont.eot');
    src: url('#{$font-path-italic}/silka-regularitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$font-path-italic}/silka-regularitalic-webfont.woff2') format('woff2'),
         url('#{$font-path-italic}/silka-regularitalic-webfont.woff') format('woff'),
         url('#{$font-path-italic}/silka-regularitalic-webfont.ttf') format('truetype');
}




@font-face {
    font-family: 'silkasemibold_italic';
    font-display: swap;
    src: url('#{$font-path-italic}/silka-semibolditalic-webfont.eot');
    src: url('#{$font-path-italic}/silka-semibolditalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$font-path-italic}/silka-semibolditalic-webfont.woff2') format('woff2'),
         url('#{$font-path-italic}/silka-semibolditalic-webfont.woff') format('woff'),
         url('#{$font-path-italic}/silka-semibolditalic-webfont.ttf') format('truetype');
}




@font-face {
    font-family: 'silkathin_italic';
    font-display: swap;
    src: url('#{$font-path-italic}/silka-thinitalic-webfont.eot');
    src: url('#{$font-path-italic}/silka-thinitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$font-path-italic}/silka-thinitalic-webfont.woff2') format('woff2'),
         url('#{$font-path-italic}/silka-thinitalic-webfont.woff') format('woff'),
         url('#{$font-path-italic}/silka-thinitalic-webfont.ttf') format('truetype');
}