.import-contact-grid .ag-cell-wrapper{
    width: 100%;
}

.import-contact-grid .ag-cell-auto-height{
    align-items: flex-start !important;
}
.icon-wrapper {
    position: absolute; 
    top: -4%; 

    z-index: 1; 
}