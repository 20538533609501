.autocomplete {
  width: 85% !important;
  min-height: 8vh;
}

input[type="text"] {
  border: none;

  &:focus {
    border: none;
  }
}

.item-highlight {
  &:hover {
    background: map-get($colors, secondary-main) 0% 0% no-repeat padding-box;
  }
}

.m-w-p-40 {
  min-width: 40%;
}

.text-wrap-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.box {
  z-index: 1;
  right: 1rem;
  top: -0.7rem;
  width: 10rem !important;
}

.mtn-1 {
  margin-top: -0.5rem !important;
}

.t-4_2 {
  top: 2.2rem;
}

.t-2_4 {
  top: 1.2rem;
}

.tn {
  &-1 {
    top: -0.5rem;
  }

  &-2 {
    top: -1rem;
  }
}

.l-p {
  &-24_5 {
    left: 24.5%;
  }

  &-49 {
    left: 49%;
  }

  &-98_5 {
    left: 98.5%;
  }

  &-99 {
    left: 99%;
  }
}

.label {
  font: map-get($fonts, caption) !important;
}

.b-shadow-mobile-header {
  box-shadow: 0px 8px 24px #0000001a;
}

.color-denari-light-filter {
  filter: invert(81%) sepia(6%) saturate(18%) hue-rotate(39deg) brightness(89%) contrast(89%) !important;
}

.scroll {
  overflow-y: auto;
  overflow-x: hidden;
  height: 84vh;
}

.edit-close-settings {
  position: absolute !important;
  top: 1.26rem;
  right: 1.5rem;
}

.pa-r-center {
  top: 50%;
  transform: translate(0%, -50%);
}

.pa-center {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.dialogue-shadow {
  filter: drop-shadow(0px 16px 40px #7090b033);
}

.header-width {
  width: 95%;
}

@media (min-width: 765px) and (max-width: 800px) {
  .header-width {
    width: 93%;
  }
}

@media (min-width: 800px) and (max-width: 1000px) {
  .header-width {
    width: 94%;
  }
}

.feature-switch {
  margin-bottom: -1rem !important;
  margin-top: -0.8rem !important;
}

.font-phone-mobile {
  font: map-get($fonts, h6) !important;

  @media (max-width: 380px) and (min-width: 310px) {
    font: map-get($fonts, caption) !important;
  }
}

.font-phone-mobile-field {
  font: map-get($fonts, h6) !important;

  @media (max-width: 430px) and (min-width: 381px) {
    font: map-get($fonts, h5-regular) !important;
  }

  @media (max-width: 380px) and (min-width: 310px) {
    font: map-get($fonts, caption) !important;
  }
}

.count-phone {
  @media (max-width: 320px) {
    position: relative;
    right: 1.5rem;
  }
}

.mobile-phone-text-view {
  width: 12vw;

  @media (max-width: 770px) and (min-width: 725px) {
    width: 7vw;
  }

  @media (max-width: 380px) and (min-width: 355px) {
    width: 13vw;
  }

  @media (max-width: 354px) and (min-width: 345px) {
    width: 14vw;
  }

  @media (max-width: 344px) and (min-width: 315px) {
    width: 15vw;
  }
}

.mh-v-60 {
  max-height: 60vh;
}

.mh-p-25 {
  max-width: 25% !important;
}

.file-upload {
  &:hover {
    background: map-get($colors, primary) 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 8px 24px map-get($colors, primary-shadow);
  }
}

.py-search {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.f-m-btn-label {
  @media (max-width: 835px) {
    margin-top: 0.125rem !important;
  }
}

.profile-avatar-upload-padding {
  padding: 4px !important;
}

.import-contact-border {
  border: 1px solid map-get($colors, import-contact-grid);
}

.badge-filter {
  height: 15px;
  min-width: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-flex;
}

.ribbon-background {
  backdrop-filter: blur(80px) !important;
  -webkit-backdrop-filter: blur(50px) !important;
  border-radius: 0px 0px 10px 10px;
}

.filter-max-w {
  max-width: 25rem;
}

.custom-label-stepper .MuiSvgIcon-root {
  width: 1.5rem !important;
  height: 2.5rem !important;
  color: map-get($colors, step-label) !important;
  border-radius: 50% !important;
}

.custom-label-stepper .MuiStepIcon-text {
  font: map-get($fonts, h6-bold) !important;
  text-align: center !important;
}

.stepper-connector .MuiStepConnector-line {
  border-color: #f5f7fa !important;
}

.custom-text-field-border {
  border: 1px solid map-get($colors, text-field-border);
}

::placeholder {
  color: map-get($colors, text-field-font);
}

::-webkit-input-placeholder {
  color: map-get($colors, text-field-font);
}

::-moz-placeholder {
  color: map-get($colors, text-field-font);
}

:-ms-input-placeholder {
  color: map-get($colors, text-field-font);
}

:-moz-placeholder {
  color: map-get($colors, text-field-font);
}

.tooltip-z-index {
  z-index: 99999 !important;
}

.f-link-hover:hover {
  color: map-get($colors, primary-light) !important;
}

.version-spacing {
  letter-spacing: 0.1875rem;
}

.pagination-input-bolder {
  border: 1px solid map-get($colors, pagination-border);

  &:focus {
    border: 1px solid map-get($colors, pagination-border);
  }
}

.pagination-bolder {
  border: 1px solid map-get($colors, pagination-border);
}

.pagination-bottom-top-bolder {
  border-bottom: 1px solid map-get($colors, pagination-border);
  border-top: 1px solid map-get($colors, pagination-border);
}

.pagination-left-border {
  border-radius: 0.25rem 0 0 0.25rem !important;
}

.pagination-right-border {
  border-radius: 0 0.25rem 0.25rem 0 !important;
}

.custom-header-mobile-view {
  box-shadow: 0px 8px 20px map-get($colors, header-mobile-view) !important;
}

.border-right-4 {
  border-right: 0.0625rem solid map-get($colors, text-field-border) !important;
}

.border-left-4 {
  border-left: 0.0625rem solid map-get($colors, text-field-border) !important;
}

.badge-subnav {
  height: 5px;
  min-width: 5px;
  width: 5px;
  border-radius: 50%;
  display: inline-flex;
}

a,
a:visited,
a:hover,
a:active {
  color: inherit;
}

.mobile-badge-subnav {
  height: 8px;
  min-width: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-flex;
}

.ml-3_1 {
  margin-left: 1.65rem;
}

.custom-border-box-size {
  box-sizing: border-box !important;
}

.contact-detail-view {
  z-index: 1 !important;
  width: 20rem !important;
}

.teams-badge-subnav {
  height: 6px;
  min-width: 6px;
  width: 6px;
  border-radius: 50%;
  display: inline-flex;
}

//safari browser
html:focus,
body:focus,
input[readonly]:focus,
applet:focus,
embed:focus,
iframe:focus,
object:focus {
  outline: none !important;
}

:focus-visible {
  outline: none !important;
}

.blink-me {
  z-index: 999;
  animation: blinker 1.5s;
  animation-iteration-count: 1;
}

@keyframes blinker {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    box-shadow: 0 0 5px #3f3e3e;
    background-color: #fafafc;
    // border: 1px solid #5f5f5f;
  }
}

.t-overflow-ellipsis {
  text-overflow: ellipsis !important;
}

.login-download-btn-border {
  border: 1px solid map-get($colors, login-download-btn-border);
}

.tag {
  padding: 0.25rem 1rem;
  margin: 0.2rem 0.25rem;
  vertical-align: baseline;
  display: inline-block;
  border-radius: 1rem;
  background: #ffeddb 0% 0% no-repeat padding-box;
  text-align: left;
  font: normal normal medium 11px/11px Silka;
  letter-spacing: 0.11px;
  color: #1450ff;

  &.contact {
    background: #e2e9ff 0% 0% no-repeat padding-box;
    color: #1450ff;
  }

  &.user {
    background: #ffeddb 0% 0% no-repeat padding-box;
    color: #ff8200;
  }

  &.default {
    background: none !important;
    visibility: hidden !important;
  }

  &.disabled {
    pointer-events: none;
    background: #a9a9a9 !important;
    color: white;
  }
}

.tags-max-width {
  max-width: 15rem;
}

.conversation-link {
  text-decoration: underline 2px solid map-get($colors, secondary);
  -webkit-text-decoration-line: underline !important;
  -webkit-text-decoration-color: map-get($colors, secondary) !important;
  -webkit-text-decoration-style: solid !important;
  text-decoration-thickness: 2px !important;
}

.profile-image-border {
  width: fit-content;
  border: 3px solid white;
  border-radius: 50%;
}

.mx-p-12 {
  margin-left: 12%;
  margin-right: 12%;
}

.mx-p-20 {
  margin-left: 20%;
  margin-right: 20%;
}

.link {
  text-decoration: underline;
  font: map-get($fonts, h4-regular);
  color: map-get($colors, primary-light);
  cursor: pointer;
}

.kafka-border {
  border: 1px solid #FFFFFF;
}

.bg-none {

  &:hover,
  &:focus,
  &:active {
    background-color: inherit !important;
  }
}

.card-body {
  display: flex;
  flex-direction: column;
  width: 312px;
  height: 224px;
  border-radius: 9px;
  margin: 10px;
  cursor: pointer;
  background-color: white;
  transition: all 0.2s ease-out;
  border: 1px solid #dce1e9;
}

.integrate-card-box-shadow {
  box-shadow: 0rem 1rem 2.5rem #7090B040;
}