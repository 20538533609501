
.active-contact {
  background: linear-gradient(to right, green 0%, rgb(229, 229, 241) 0%);
}

.active-blocked-contact {
  background: linear-gradient(to right, red 3px, rgb(229, 229, 241) 0%);
}

.active-unsubscribe-contact {
  background: linear-gradient(to right, orange 3px, rgb(229, 229, 241) 0%);
}

.blocked-contact {
  background: linear-gradient(to right, red 3px, white 0%);
}

.active-row {
  background: linear-gradient(to right, green 3px, white 0%);
}

.inActive-row {
  background: linear-gradient(to right, red 3px, white 0%);
}

.unSubscribe-contact {
  background: linear-gradient(to right, orange 3px, white 0%);
}

.reset_password {
  width: 300px !important;
  height: 36px;
  padding-top: 6px !important;
}

.date-hr-separtor {
  display: flex;
  font-size: 10px !important;
  align-items: center;
  text-align: center;
  clear: both;
  color: #353434;
  margin-bottom: 10px;
}

.date-hr-separtor::before,
.date-hr-separtor::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid silver;
}

.date-hr-separtor::before {
  margin-right: 0.25em;
}

.date-hr-separtor::after {
  margin-left: 0.25em;
}

.hide-element {
  display: none;
}

.tick-mark:after {
  content: "\2713";
  padding: 0 6px 0 0;
  float: right;
  margin-top: -21px;
  font-size: 13px;
}

tbody .MuiTableCell-root {
  padding: 5px 15px !important;

  &.px-0 {
    padding: 5px 0px !important;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

progress {
  border: none;
  width: 100%;
  height: 1rem;
  background-color: rgb(240, 240, 236);
}

progress {
  color: lightblue;
}

.progress-green > progress::-webkit-progress-value {
  background: #2ec973;
}

.progress-orange > progress::-webkit-progress-value {
  background: orange !important;
}

.progress-red > progress::-webkit-progress-value {
  background: #d63031;
}

progress::-webkit-progress-bar {
  background: rgb(242, 242, 245);
}

.vertical_dotted_line {
  height: 2.4vh;
  margin-top: -0.5rem;
  position: relative;

  @media (min-width: 375px) and (max-width: 410px) {
    margin-top: -1.5rem;
  }

  @media (min-width: 310px) and (max-width: 370px) {
    margin-top: -2rem;
  }
}

.hi {
  margin-top: 1rem;
  position: absolute;
}

.hi_50 {
  margin-top: 0.5rem;
  position: absolute;
}

.vertical_dotted_line_50 {
  height: 1.5vh;
  margin-top: 0rem;
  position: relative;

  @media (min-width: 375px) and (max-width: 410px) {
    margin-top: -1rem;
  }

  @media (min-width: 310px) and (max-width: 374px) {
    margin-top: -1.5rem;
  }
}

.bg-mobile-full-view {
  margin: 0 -1rem 0 -1rem !important;
  width: -webkit-fill-available !important;
  height: 100% !important;
}

@media print {
  @page {
    size: A4;
  }

  body * {
    visibility: hidden;
  }

  #print,
  #print * {
    visibility: visible;
  }
  #print {
    position: absolute;
    top: 35%;
    left: 0;
  }

#print-font{
  font: normal normal normal 14px/21px Helvetica !important;
}
 

html, body {
  height:100vh; 
  margin: 0 !important; 
  padding: 0 !important;
  overflow: hidden;
}

}
